import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconTransfer = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="26"
      height="26"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path d="M19.5 16.5H6M5.5 8.5L19 8.5" stroke="#121923" strokeWidth="1.2"></path>{' '}
        <path d="M16 13L19.5 16.5L16 20M9 12L5.5 8.5L9 5" stroke="#121923" strokeWidth="1.2"></path>{' '}
      </g>
    </svg>
  );
};

IconTransfer.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconTransfer.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconTransfer;
