import React from 'react';
import { Heading } from '..';
import { richText } from '../../util/richText';

import css from './RichText.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const RichText = props => {
  const { text, heading, className } = props;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  return text ? (
    <div className={className ? className : css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={css.text}>{content}</p>
    </div>
  ) : null;
};

export default RichText;
