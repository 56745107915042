import React from 'react';
import NamedLink from '../NamedLink/NamedLink';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';
import { LazyImage, MIN_LENGTH_FOR_LONG_WORDS } from './ListingCard';
import { richText } from '../../util/richText';
import { AvatarSmall } from '../Avatar/Avatar';
import { FormattedMessage } from '../../util/reactIntl';
import Icons from '../Icons/Icons';
import { capitalizeFirstLetter, formatEventDate, isArrayLength } from '../../util/genericHelpers';
import classNames from 'classnames';

import css from './ListingCard.module.css';

function RenderListingCard(props) {
  const {
    classes,
    id,
    slug,
    aspectWidth,
    aspectHeight,
    setActivePropsMaybe,
    title,
    firstImage,
    variants,
    renderSizes,
    gameIcon,
    showAuthorInfo,
    author,
    authorName,
    renderPriceMaybe,
    publicData,
    format,
    config,
    nextEvent,
    mapTournamentDetails,
    eventType,
  } = props;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.infoLeft}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          <div className={css.otherInfo}>
            <label htmlFor="format">Format :</label>
            <span>{capitalizeFirstLetter(format)}</span>
          </div>
          <div className={css.otherInfo}>
            <label htmlFor="format">Turnierdetails :</label>
            <div className={css.tournamentDetails}>
              {isArrayLength(mapTournamentDetails)
                ? mapTournamentDetails.map((mt, i) => {
                    const isSelected = mt?.selected;
                    return (
                      <span
                        key={i}
                        className={classNames({
                          [css.checked]: isSelected,
                        })}
                      >
                        {isSelected ? <Icons name="checkMark" /> : null}
                        {mt?.label}
                      </span>
                    );
                  })
                : null}
            </div>
          </div>

          <div className={css.otherInfo}>
            <label htmlFor="format">{eventType} :</label>
            <span>{formatEventDate(nextEvent)}</span>
          </div>
          {renderPriceMaybe}
        </div>
        <div className={css.infoRight}>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <AvatarSmall className={css.avatar} user={author} />
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
          <div className={css.listingImg}>
            <img src={gameIcon?.icon} alt={title} />
          </div>
        </div>
      </div>
    </NamedLink>
  );
}

export default RenderListingCard;
