import React from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import loadable from '@loadable/component';
import { ValidationError } from '../../components';

// LocationAutocompleteInputImpl is a big component that includes code for both Mapbox and Google Maps
// It is loaded dynamically - i.e., it is split into its own code chunk.
const LocationAutocompleteInputImpl = loadable(() =>
  import(/* webpackChunkName: "LocationAutocompleteInputImpl" */ './LocationAutocompleteInputImpl')
);

const LocationAutocompleteInputComponent = ({
  rootClassName,
  labelClassName,
  hideErrorMessage,
  isLandingDateRange,
  input,
  label,
  meta,
  ...otherProps
}) => {
  const value = input.value;

  const locationAutocompleteProps = {
    label,
    isLandingDateRange,
    meta,
    input: { ...input, value },
    ...otherProps,
  };

  const labelInfo = label ? (
    <label className={labelClassName} htmlFor={input.name}>
      {label}
    </label>
  ) : null;

  return (
    <div className={rootClassName}>
      {labelInfo}
      <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
      {!hideErrorMessage && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  label: null,
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
};

export default LocationAutocompleteInputImpl;

export const FieldLocationAutocompleteInput = props => (
  <Field component={LocationAutocompleteInputComponent} {...props} />
);
