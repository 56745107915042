import { fetchBlog, fetchBlogs, fetchTagsFilter } from '../util/api';

export const SEARCH_BLOGS_REQUEST = 'app/strapi/SEARCH_BLOGS_REQUEST';
export const SEARCH_BLOGS_SUCCESS = 'app/strapi/SEARCH_BLOGS_SUCCESS';
export const SEARCH_BLOGS_ERROR = 'app/strapi/SEARCH_BLOGS_ERROR';
export const SEARCH_SINGLE_BLOGS_SUCCESS = 'app/strapi/SEARCH_SINGLE_BLOGS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  searchBlogsError: null,
  blogsData: [],
  singleBlogData: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_BLOGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
      };
    case SEARCH_BLOGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        blogsData: payload?.data?.blogs || [],
        blogTags: payload?.data?.filters || [],
      };
    case SEARCH_SINGLE_BLOGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        singleBlogData: payload?.data || [],
      };
    case SEARCH_BLOGS_ERROR:
      return { ...state, searchInProgress: false, searchBlogsError: true };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const getBlogsInProgress = state => state.strapi?.searchInProgress;
export const getBlogsSelector = state => state.strapi?.blogsData;
export const getBlogsTagsSelector = state => state.strapi?.blogTags;
export const getBlogSelector = state => state.strapi.singleBlogData;

// ================ Action creators ================ //

export const searchBlogsRequest = () => ({
  type: SEARCH_BLOGS_REQUEST,
});

export const searchBlogsSuccess = response => ({
  type: SEARCH_BLOGS_SUCCESS,
  payload: { data: response },
});

export const searchSingleBlogsSuccess = response => ({
  type: SEARCH_SINGLE_BLOGS_SUCCESS,
  payload: { data: response },
});

export const searchBlogsError = () => ({
  type: SEARCH_BLOGS_ERROR,
});

// ================ Thunks ================ //

export const fetchSingleBlog = params => async (dispatch, getState, sdk) => {
  dispatch(searchBlogsRequest());
  try {
    const response = await fetchBlog({ ...params });
    const tagsResponse = await fetchTagsFilter({});
    dispatch(searchSingleBlogsSuccess(response?.blog));
    dispatch(
      searchBlogsSuccess({
        filters: tagsResponse ? tagsResponse?.tags : [],
      })
    );
    return response;
  } catch (error) {
    dispatch(searchBlogsError());
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchAllBlogs = params => async (dispatch, getState, sdk) => {
  dispatch(searchBlogsRequest());

  try {
    let blogsResponse, tagsResponse;

    // Fetch blogs
    blogsResponse = await fetchBlogs({ ...params });
    tagsResponse = await fetchTagsFilter({});

    dispatch(
      searchBlogsSuccess({
        blogs: blogsResponse?.blogs,
        filters: tagsResponse ? tagsResponse?.tags : [],
      })
    );
    return blogsResponse?.blogs;
  } catch (error) {
    dispatch(searchBlogsError());
  }
};
