import React from 'react';
import PropTypes from 'prop-types';

import css from './IconArrowTop.module.css';

const IconArrowTop = props => {
  const { className } = props;
  return (
    <svg
      width={24}
      height={24}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g id="Arrow / Arrow_Up_Right_SM">
          {' '}
          <path
            id="Vector"
            d="M8 16L16 8M16 8H10M16 8V14"
            stroke="#484848"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

IconArrowTop.defaultProps = { className: null };

const { string } = PropTypes;

IconArrowTop.propTypes = { className: string };

export default IconArrowTop;
