import React from 'react';
import PropTypes from 'prop-types';
import { convertMarkdownToReact } from '../../util/markdownHelpers';
import './StrapiContentRenderer.css';

// Block Components
const Paragraph = ({ children }) => <>{children ? convertMarkdownToReact(children) : ''}</>;
const Heading = ({ level, children }) => {
  const Tag = `h${level}`;
  return <Tag>{convertMarkdownToReact(children)}</Tag>;
};
const List = ({ format, children }) => {
  const Tag = format === 'ordered' ? 'ol' : 'ul';
  return <Tag>{convertMarkdownToReact(children)}</Tag>;
};
const Quote = ({ children }) => (
  <blockquote>{children ? convertMarkdownToReact(children) : ''}</blockquote>
);
const CodeBlock = ({ plainText }) => (
  <pre>
    <code>{plainText}</code>
  </pre>
);
const Image = ({ src, alt }) => <img src={src} alt={alt} />;
const Link = ({ url, children }) => <a href={url}>{convertMarkdownToReact(children)}</a>;

// Inline Modifier Components
const Bold = ({ children }) => <strong>{convertMarkdownToReact(children)}</strong>;
const Italic = ({ children }) => <em>{convertMarkdownToReact(children)}</em>;
const Underline = ({ children }) => <u>{convertMarkdownToReact(children)}</u>;
const Strikethrough = ({ children }) => <s>{convertMarkdownToReact(children)}</s>;
const InlineCode = ({ children }) => <code>{convertMarkdownToReact(children)}</code>;

// Render Inline Modifiers
const renderInlineModifiers = text => {
  if (!Array.isArray(text)) return text;
  return text.map((fragment, index) => {
    if (!text) return null;
    if (typeof fragment === 'string') return fragment;

    switch (fragment.type) {
      case 'bold':
        return <Bold key={index}>{renderInlineModifiers(fragment.children)}</Bold>;
      case 'italic':
        return <Italic key={index}>{renderInlineModifiers(fragment.children)}</Italic>;
      case 'underline':
        return <Underline key={index}>{renderInlineModifiers(fragment.children)}</Underline>;
      case 'strikethrough':
        return (
          <Strikethrough key={index}>{renderInlineModifiers(fragment.children)}</Strikethrough>
        );
      case 'code':
        return <InlineCode key={index}>{renderInlineModifiers(fragment.children)}</InlineCode>;
      default:
        return null;
    }
  });
};

// Renderer Function
const renderNode = node => {
  switch (node.type) {
    case 'paragraph':
      return <Paragraph key={node.id}>{node.children.map(renderNode)}</Paragraph>;
    case 'heading':
      return (
        <Heading key={node.id} level={node.level}>
          {node.children.map(renderNode)}
        </Heading>
      );
    case 'list':
      return (
        <List key={node.id} format={node.format}>
          {node.children.map(renderNode)}
        </List>
      );
    case 'quote':
      return <Quote key={node.id}>{node.children.map(renderNode)}</Quote>;
    case 'code':
      return <CodeBlock key={node.id} plainText={node.plainText} />;
    case 'image':
      return <Image key={node.id} src={node.image.src} alt={node.image.alt} />;
    case 'link':
      return (
        <Link key={node.id} url={node.url}>
          {node.children.map(renderNode)}
        </Link>
      );
    case 'text':
      return renderInlineModifiers(node.text);
    default:
      return null;
  }
};

// Main component to render the JSON structure
const StrapiContentRenderer = ({ content }) => {
  if (!content) {
    return '';
  }
  return <>{content.map(renderNode)}</>;
};

StrapiContentRenderer.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StrapiContentRenderer;
