import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../util/api';
import defaultConfig from '../config/configDefault';

export const ListingApi = createApi({
  reducerPath: 'ListingApi',
  tagTypes: ['Listing', 'ListingAvailabilityExceptions'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl(defaultConfig.marketplaceRootURL)}/api/listings/`,
    credentials: 'include',
  }),
  endpoints: builder => ({
    showListing: builder.query({
      query: id => ({ url: `${id}` }),
      transformResponse: response => response.data,
      providesTags: (result, error, id) => [{ type: 'Listing', id }],
    }),
    showListingAvailabilityExceptions: builder.query({
      query: ({ listingId, start, end }) => {
        return {
          url: `${listingId}/availability-exceptions`,
          params: {
            start,
            end,
          },
        };
      },
      transformResponse: response => response.data,
    }),
    showListingPlayers: builder.query({
      query: ({ listingId, start, end }) => {
        return {
          url: `${listingId}/players`,
          params: {
            start,
            end,
          },
        };
      },
    }),
    showBookings: builder.query({
      query: ({ listingId, start, end }) => {
        return {
          url: `${listingId}/bookings`,
          params: {
            start,
            end,
          },
        };
      },
    }),
    increaseViewCount: builder.mutation({
      query: listingId => {
        return {
          url: `${listingId}/increase-view-count`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useShowListingQuery,
  useShowListingAvailabilityExceptionsQuery,
  useShowListingPlayersQuery,
  useShowBookingsQuery,
  useIncreaseViewCountMutation,
  reducer,
} = ListingApi;
