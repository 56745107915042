import { games } from '../config/games';
import { isArrayLength, searchForKey } from './genericHelpers';

export const getEventDates = listing => {
  return listing?.attributes?.publicData?.eventDates;
};

export const getDisplayName = user => {
  return (
    user?.attributes?.profile?.publicData?.displayName || user?.attributes?.profile?.displayName
  );
};

export const findValidGameId = (listing, currentUser) => {
  const game = listing?.attributes?.publicData?.game;
  const userProtectedData = currentUser?.attributes?.profile?.protectedData;

  const validTradingCards =
    isArrayLength(userProtectedData?.validTradingCards) &&
    searchForKey(userProtectedData?.validTradingCards, game);
  const validTradingCardId = userProtectedData && userProtectedData[(games[game]?.gamerIdField)];

  return validTradingCards || validTradingCardId;
};

export const calculateZoomLevel = distance => {
  // Assuming a constant factor to convert distance to zoom level
  // You may need to adjust this factor based on your specific use case
  const zoomFactor = 10; // Adjust this factor as needed
  // Formula to calculate zoom level based on distance
  const zoomLevel = Math.round(14 - Math.log(distance / 500) / Math.log(2));
  return zoomLevel;
};

export const displayEventType = (frequency, eventDates) => {
  if (eventDates?.length === 1 || ['oneTime', 'oneItem'].includes(frequency)) {
    return 'Termin';
  } else {
    return 'Regelmäßiges Turnier; Nächster Termin';
  }
};

export const getNextEventDate = eventDates => {
  // Get the current time as a Date object
  const now = new Date();

  // Filter out past dates and sort remaining dates in ascending order
  const sortedDates = eventDates
    .map(timestamp => new Date(timestamp)) // Convert each timestamp to a Date object
    .filter(date => date > now) // Keep only future dates
    .sort((a, b) => a - b); // Sort in ascending order

  // Return the next available date or null if none is available
  return sortedDates.length > 0 ? sortedDates[0] : null;
};

export const getImagesLink = (images, url) => {
  // Generate an array of image URLs using the base URL and the image object's URL attribute.
  const imageUrls =
    isArrayLength(images) &&
    images.map(image => {
      const imageUrl = `${url}${image?.attributes?.url}`;
      return imageUrl;
    });

  // Return the array of image URLs.
  return imageUrls;
};

export const getBlogData = blog => {
  // Get the base URL from the configuration.
  const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

  // Get the blog images and generate image URLs using the base URL.
  const blogImages = blog?.attributes?.images?.data || [];
  const imagesLink = blogImages?.length && getImagesLink(blogImages, baseUrl);

  // Get the blog title and description.
  const title = blog?.attributes?.title || '';
  const description = blog?.attributes?.description || '';
  const tags = blog?.attributes?.tags || '';
  const createdAt = blog?.attributes?.createdAt || '';

  // Return an object containing the blog title, description, and the first image URL (if available).
  return { title, description, tags, createdAt, image: imagesLink && imagesLink[0] };
};
