export const TOURNAMENT_DETAILS = 'tournamentdetails';
export const RULE = 'rule';
export const HAS_NO_ID_YET = 'Has no ID yet';

export const GAME = 'game';
export const FORMAT = 'format';
export const RULES = 'rules';
export const MAGIC_THE_GATHERING = 'magic-the-gathering';
export const CASUAL = 'casual';
export const DECKLIST = 'decklist';
