export const games = {
  digimon: {
    title: 'Digimon',
    fields: ['formatDigimon', 'rulesDigimon'],
    gamerIdField: 'DigimonBandaID',
  },
  'flesh-and-blood': {
    title: 'Flesh and Blood',
    fields: ['formatFleshAndBlood', 'rulesFleshAndBlood'],
    gamerIdField: 'FleshandBloodGemID',
  },
  lorcana: {
    title: 'Lorcana',
    fields: ['formatLorcana'],
    gamerIdField: 'LorcanaID',
  },
  'magic-the-gathering': {
    title: 'Magic the Gathering',
    fields: [
      'formatMagicTheGathering',
      'RulesMagicTheGathering',
      'twoHeadedGiantMagicTheGathering',
    ],
    gamerIdField: 'MtGWizardEmail',
  },
  'star-wars': {
    title: 'Star Wars',
    fields: ['formats'],
    gamerIdField: 'SWSpielerID',
  },
  'one-piece': {
    title: 'One Piece',
    fields: ['formatOnePiece', 'buddyBattleOnePiece'],
    gamerIdField: 'OnePieceBandaiID',
  },
  pokemon: {
    title: 'Pokemon',
    fields: ['formatPokemon'],
    gamerIdField: 'PokemonPlayerID',
  },
  'yu-gi-oh': {
    title: 'Yu-Gi-Oh!',
    fields: ['formatYugioh', 'tierLevelYugioh'],
    gamerIdField: 'YuGiOhKonamiID',
  },
  dbscg: {
    title: 'Dragon Ball Super Card Game',
    fields: ['formatDragonBallSuperCardGame'],
    gamerIdField: 'DragonBallSuperCardGameID',
  },
};

const ALLWAYS_INCLUDE_FIELDS = ['game', 'tournamentdetails'];

export const getGameFieldsConfig = ({ listingFieldsConfig, game }) => {
  const gameSettings = games[game];
  const gameFieldsConfig = listingFieldsConfig.filter(fieldConfig => {
    const { key } = fieldConfig;
    if (gameSettings) {
      if (gameSettings.fields && gameSettings.fields.indexOf(key) >= 0) {
        return true;
      }
    }
    if (ALLWAYS_INCLUDE_FIELDS.indexOf(key) >= 0) {
      return true;
    }
    return false;
  });
  return gameFieldsConfig;
};

export const getGamerIdField = game => {
  return games[game].gamerIdField;
};
