/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes, LISTING_UNIT_TYPES } from '../../util/types';
import { formatMoney } from '../../util/currency';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';

import css from './OrderBreakdown.module.css';

export const OrderBreakdownFreeComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    // currency,
    // marketplaceName,
  } = props;

  const isCustomer = userRole === 'customer'; // eslint-disable-line no-unused-vars
  const isProvider = userRole === 'provider'; // eslint-disable-line no-unused-vars
  const lineItems = transaction.attributes.lineItems;
  const unitLineItem = lineItems?.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LineItemBookingPeriod
        booking={booking}
        code={lineItemUnitType}
        dateType={dateType}
        timeZone={timeZone}
      />

      <LineItemBasePriceMaybe lineItems={lineItems} code={lineItemUnitType} intl={intl} />
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          <FormattedMessage id="OrderBreakdown.total" />
        </div>
        <div className={css.totalPrice}>{formatMoney(intl, transaction.attributes.payinTotal)}</div>
      </div>
      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.willPayOnSpot" />
        </span>
      </div>
    </div>
  );
};

OrderBreakdownFreeComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownFreeComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdownFree = injectIntl(OrderBreakdownFreeComponent);

OrderBreakdownFree.displayName = 'OrderBreakdownFree';

export default OrderBreakdownFree;
