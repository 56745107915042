import starwarsLabel from '../assets/starwars.png';

export const gameLabels = [
  {
    label: 'Digimon',
    key: 'digimon',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/3e/f1a148f0591d40695f9de0381b08998b426470?auto=format&crop=edges&fit=crop&h=225&w=400&s=f5eda47283d3435cd545d6bb262245a8',
  },
  {
    label: 'Flesh and Blood',
    key: 'flesh-and-blood',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/7f/57fdb3d9e92a591bc2157e6edf1a5e6858a0b5?auto=format&crop=edges&fit=crop&h=225&w=400&s=c8604a78238aebb99115fcda06acbafe',
  },
  {
    label: 'Lorcana',
    key: 'lorcana',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/27/731085558505ef9626af33557ff4ebad38d4fb?auto=format&crop=edges&fit=crop&h=225&w=400&s=f99fd74c17a87b4d215a1b05957f4af3',
  },
  {
    label: 'Magic the Gathering',
    key: 'magic-the-gathering',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/7b/57344a23546c393f15bcf816c86c31584d8e5d?auto=format&crop=edges&fit=crop&h=225&w=400&s=3c02ca303d91271d0e4aa448cdbb1c03',
  },
  {
    label: 'One Piece',
    key: 'one-piece',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/3f/89a82cd18d61b6f98d5881eeff891b8cc6ca19?auto=format&crop=edges&fit=crop&h=225&w=400&s=18e9f00a921123d9c4eb80db09cd4a3c',
  },
  {
    label: 'Pokemon',
    key: 'pokemon',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/18/15832fac42a19baedf59bcf374d6fde88e61db?auto=format&crop=edges&fit=crop&h=225&w=400&s=618a34b29c25d60cf0d6f33f56ffd343',
  },
  {
    label: 'Yu-Gi-Oh!',
    key: 'yu-gi-oh',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/87/e83e3ebd19b402ad8871202e318cd875401224?auto=format&crop=edges&fit=crop&h=225&w=400&s=59a67556d26055b6f14977c5495cc01e',
  },
  {
    label: 'Star Wars',
    key: 'star-wars',
    icon: starwarsLabel,
  },
  {
    label: 'Dragon Ball Super Card Game',
    key: 'dbscg',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/9a/6a3ceb6d60de4c812152a32d14ca6b3e24d404?auto=format&crop=edges&fit=crop&h=225&w=400&s=7614d208fefc2fe65686d32a11819885',
  },
];

export const tradingCardTypes = gameLabels.map(g => g.key);
