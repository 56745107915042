import { setupListeners } from '@reduxjs/toolkit/query';
import createReducer from './reducers';
import * as analytics from './analytics/analytics';
import appSettings from './config/settings';
import { configureStore } from '@reduxjs/toolkit';
import { UserApi } from './ducks/UserApi';
import { ListingApi } from './ducks/ListingApi';

/**
 * Create a new store with the given initial state. Adds Redux
 * middleware and enhancers.
 */
export default function configureAppStore(initialState = {}, sdk = null, analyticsHandlers = []) {
  const store = configureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: { extraArgument: sdk },
        serializableCheck: false,
        immutableCheck: false,
      }).concat([
        UserApi.middleware,
        ListingApi.middleware,
        analytics.createMiddleware(analyticsHandlers),
      ]),
    devTools: appSettings.dev,
    preloadedState: initialState,
  });

  setupListeners(store.dispatch);

  return store;
}
